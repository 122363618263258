<template>
  <section :class="`answer detect_deviece_${deviceType} state_${deviceType}`">
    <HeadBar title="The Book of Answer" :deviceType="deviceType" />
    <section class="answer-content " @click="refresh">
      <div class="memo">
        Click any area to refresh the current page <img src="@/assets/refresh.png" alt="">
      </div>
      <div class="banner">
        <div class="answer-banner-t-row">
          <div class="answer-banner-txt">
            {{ $t(answerCode) }}
          </div>
        </div>

        <div class="svgaPage">
          <div id="svga" />
        </div>

        <img v-show="!isPlaying" src="@/assets/book.png" class="book" alt="">
        <img src="@/assets/m_banner.png" alt="">
      </div>

      <div v-if="Object.keys(translations).length > 0" class="answer-row">
        <div v-for="(translation, lang) in translations" :key="lang">
          {{ translation }}
        </div>
      </div>

      <div class="answer-blog-row bg-white">
        <div class="home-intro-title">
          Something may help
        </div>
        <div class="home-intro-content last">
          <div class="time-page-card-row">
            <div v-for="(data,i) in card" :key="i" class="time-page-card-item"
              @click="goto(data.routename)">
              <div class="time-page-card-image">
                <img :src="data.img" :alt="data.title">
              </div>
              <div class="time-page-card-content">
                <div class="time-page-card-title">
                  {{ data.title }}
                </div>
                <div class="time-page-card-desc">
                  {{ data.desc }}
                </div>
              </div>
              <div class="time-page-card-btn">
                Read more
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="showToast" class="toast">
      Your daily view limit has been reached Please come back tomorrow
    </div>

    <FootBar :deviceType="deviceType" />
  </section>
</template>

<script>
import common from '@/mixin/common.js';
import HeadBar from '@/pages/components/head.vue';
import FootBar from '@/pages/components/footer.vue';
import i18n from '@/i18n';

import { mapGetters } from 'vuex';
import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    HeadBar,
    FootBar
  },
  mixins: [common],
  data () {
    return {
      languages: ['ar', 'zh', 'de', 'en', 'es', 'fr', 'hi', 'ja', 'ko', 'pt', 'ru'],
      translations: {},
      answerCode: null,
      card: [
        {
          img: require('@/assets/NWI1NDQxNDgwYjdiMDYwMjU2NDA5MWU2ZjI3ODEwMGVfQUZlNkc2ZHI3Z2UyUDhkWVV4N0lJcnI3T1UyTUN3N2VfVG9rZW46VzBhWWJxVU9pbzRqSHB4RFBjYmNPY3d0bnlnXzE3MjYyMTExNDY6MTcyNjIxNDc0Nl9WNA.jpg'),
          title: 'Open the Book of Answers to Resolve Your Doubts',
          desc: "In our fast-paced lives, we often face the dilemma of making choices. The author has thoughtfully summarized the following excerpts from the Book of Answers, hoping that they will guide you to find your life's North Star. This article is especially for new university students.",
          routename: 'blog1'
        },
        {
          img: require('@/assets/YmMwMGNmNWE5NzA3OTgxMDQyNmRiMDBkNDMxNzc2NTZfc3BFRmpVQUtSSlllNkhHZmpDUlJPdnI4ZVZJR0hlMEpfVG9rZW46UGhzU2I2dTY1b0N0Nzh4WmRYSGNlZ3Nibk9nXzE3MjYyMTE2NzE6MTcyNjIxNTI3MV9WNA.webp'),
          title: "You Think It's Normal, But It Could Be Long-Term Anxiety: 9 Signs",
          desc: "Anxiety is an emotion everyone experiences, but few realize that anxiety disorders can severely impact one's life. This psychological condition, commonly known as anxiety disorder, can be hard to detect even by those suffering from it.",
          routename: 'blog2'
        },
        {
          img: require('@/assets/YTVmYTc3NWNmZWQzNTIyNTI0MjU2OTQ5OTE5ZWU4YjBfRHFzMHFPS21Yb3VJZUVqM1dOdmJNeXlhbTh3QVlGRzRfVG9rZW46THo5Z2JUdnlsb2xSVWl4aHZlQWM2YW8wbk9nXzE3MjYyMTIwMzI6MTcyNjIxNTYzMl9WNA.jpg'),
          title: 'Three Steps to Cure Your Decision Paralysis',
          desc: 'Struggling to make a choice between two options, always worrying about missing out or making a mistake, constantly questioning potential risks, and seeking a perfect solution—these are all signs of decision paralysis. Procrastinating until the last moment and feeling exhausted from overthinking can lead to missed opportunities or leaving the decision to someone else.',
          routename: 'blog3'
        },
        {
          img: require('@/assets/YWM5YTEwNjc1M2ZlODgxY2E0NjAyZTBiYzZhYjdiZjdfWUEzWnp5ZHo0eXhxVWFoVTkwTjNzS25kcmMwYWhpZ2NfVG9rZW46WTZWMWJlbDhRb3ZDSXJ4bXg3cmNheXIxbkJoXzE3MjYyMTIxNTk6MTcyNjIxNTc1OV9WNA.jpg'),
          title: 'Stress Management Guide: 5 Scientific Methods to Help You Relieve Stress...',
          desc: 'Transitioning from “busy” to “efficient” involves adopting systematic time management methods and committing to ongoing practice. By setting clear goals, creating detailed plans, prioritizing tasks, overcoming procrastination, managing distractions, adjusting regularly, prioritizing rest, using technological tools, developing good habits, and seeking support, you can significantly improve productivity in both work and life. Mastering these techniques will help you achieve greater organization and efficiency in your daily routines.', routename: 'blog4'
        },
      ],
      isPlaying: false, // 播放状态
      showToast: false,
      clickCount: 0,
      maxClicks: 50,
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  async created () {
    await this.initCode();
    this.loadTranslations();
    this.checkClickCount();
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.preloadSVGA(1);

  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    async playSvga () {
      await this.loadAndPlaySVGA();
    },
    async loadAndPlaySVGA () {
      try {
        const videoItem = await this.preloadSVGA();
        const player = this.createSVGAPlayer();
        player.loops = 5;
        player.setVideoItem(videoItem);
        player.startAnimation();
        this.isPlaying = true; // 设置为播放状态
        console.log('start');
        // 监听播放完成事件
        player.onFinished(() => {
          this.isPlaying = false; // 播放结束时设置状态
          console.log('done');
          this.initCode();
          this.loadTranslations(); // 调用 loadTranslations 以更新所有翻译
        });
      } catch (error) {
        console.error("SVGA加载失败:", error);
      }
    },
    preloadSVGA () {
      // const url = "https://ptc-dressup-sg2.obs.ap-southeast-3.myhuaweicloud.com/rest/test/20231214/send.svga"
      const url = '/animate.svga'
      const cacheKey = "createCache"
      const SVGA = require("svgaplayerweb");

      return new Promise((resolve, reject) => {
        if (this[cacheKey]) {
          resolve(this[cacheKey]);
        } else {
          const parser = new SVGA.Parser();
          parser.load(
            url,
            (videoItem) => {
              this[cacheKey] = videoItem;
              resolve(videoItem);
            },
            reject
          );
        }
      });
    },
    playAnimate () {
      this.playSvga(1);
    },
    createSVGAPlayer () {
      const SVGA = require("svgaplayerweb");

      const elementId = "#svga";
      return new SVGA.Player(elementId);
    },
    initCode () {
      this.answerCode = `A${Math.floor(Math.random() * 269) + 2}`;
    },
    refresh () {
      console.log('refresh');
      this.incrementClickCount();
    },
    loadTranslations () {
      console.log(this.answerCode);
      this.languages.forEach((lang) => {
        this.$set(this.translations, lang, i18n.messages[lang][this.answerCode] || `No translation for ${lang}`);
      });
      console.log(this.translations); // 打印 translations 以确认更新
    },
    getCurrentUTCMidnight () {
      // 获取当前 UTC 时间的0点
      const now = new Date();
      return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0));
    },
    checkClickCount () {
      // 获取存储的点击次数和时间
      const storedData = JSON.parse(localStorage.getItem('clickData')) || {};
      const currentUTCMidnight = this.getCurrentUTCMidnight().getTime();

      // 如果存储时间小于今天 UTC 0点，则重置计数
      if (storedData.date < currentUTCMidnight) {
        this.resetClickCount();
      } else {
        // 否则加载存储的点击次数
        this.clickCount = storedData.count || 0;
        this.showToast = this.clickCount > this.maxClicks;
      }
    },
    incrementClickCount () {
      console.log(this.clickCount);
      // 检查是否超出最大点击次数
      if (this.clickCount > this.maxClicks) {
        console.log('end');
        this.showToast = true;
        return
      }
      // 增加点击次数
      this.clickCount += 1;

      this.playAnimate()

      // 更新存储
      localStorage.setItem(
        'clickData',
        JSON.stringify({
          count: this.clickCount,
          date: this.getCurrentUTCMidnight().getTime(),
        })
      );

    },
    resetClickCount () {
      // 重置点击次数
      this.clickCount = 0;
      this.showToast = false;
      localStorage.setItem(
        'clickData',
        JSON.stringify({
          count: this.clickCount,
          date: this.getCurrentUTCMidnight().getTime(),
        })
      );
    },
    goto (name) {
      this.$router.push({ name: name });
    },
  }
}
</script>
